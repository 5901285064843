// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCW-MCDAUsNlIB43pJMs7ezQdy2fdIr0p8",
    authDomain: "remed-fey.firebaseapp.com",
    databaseURL: "https://remed-fey.firebaseio.com",
    projectId: "remed-fey",
    storageBucket: "remed-fey.appspot.com",
    messagingSenderId: "792174290900"
  },

  admob_settings: {
    app_id: 'ca-app-pub-6132999901065712~9238382306', //'ca-app-pub-3940256099942544~3347511713', //'ca-app-pub-6132999901065712~9238382306',
    banner_id: 'ca-app-pub-6132999901065712/2672973953', //'ca-app-pub-3940256099942544/6300978111', //'ca-app-pub-6132999901065712/2672973953',
    interstitial_id: 'ca-app-pub-6132999901065712/1168320594' //'ca-app-pub-3940256099942544/1033173712' //'ca-app-pub-6132999901065712/1168320594'
  },

  remed_lakay_uid: 'jpJJggu68oTAamD9rXciPbcFzoj1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// android ID: com.remedlakay.app
// remed-lakay APP ID Admob Android: ca-app-pub-6132999901065712~6911322626
// cordova plugin add cordova-plugin-admob-free --save --variable ADMOB_APP_ID="ca-app-pub-6132999901065712~6911322626"
// android-banner ID: ca-app-pub-6132999901065712/3686587000
// android-interstitial ID: ca-app-pub-6132999901065712/2397362545

// implementation "com.google.android.gms:play-services-base:17.1.0"
//     implementation "com.google.android.gms:play-services-ads:19.0.0"
//     implementation 'androidx.core:core:1.2.0'
//     implementation "com.google.firebase:firebase-core:17.2.3"
//     implementation "com.google.firebase:firebase-messaging:20.1.2"
//     implementation 'androidx.annotation:annotation:1.1.0'

// gradle.properties
// android.useAndroidX=true
// android.enableJetifier=true

//AndroidManifest.xml
//<meta-data android:name="com.google.firebase.messaging.default_notification_icon" android:resource="@mipmap/ic_launcher"/>
//<meta-data android:name="com.google.firebase.messaging.default_notification_icon" android:resource="@drawable/notification_icon"/>


// ios ID: remed-fey-app
// remed-lakay APP ID Admob iOS: ca-app-pub-6132999901065712~9238382306
// Put the below inside the <platform name="ios"> tag in config.xml
// <config-file target="*-Info.plist" parent="GADApplicationIdentifier">
//     <stringca-app-pub-6132999901065712~9238382306</string>
// </config-file>
// Declare that your app is an Ad Manager app by adding the GADIsAdManagerApp key with a <true/> value to your app's Info.plist (https://developers.google.com/ad-manager/mobile-ads-sdk/ios/quick-start#update_your_infoplist)
// GADIsAdManagerApp      BOOLEAN     YES
// ios-banner ID: ca-app-pub-6132999901065712/2672973953
// ios-interstitial ID: ca-app-pub-6132999901065712/1168320594
// ios-native-advanced ID: ca-app-pub-6132999901065712/8108261322
// enable remote notifications: https://stackoverflow.com/questions/45440627/do-remote-push-notifications-require-to-add-uibackgroundmodes-in-info-plist

// test ads: ca-app-pub-3940256099942544~3347511713

// https://firebase.google.com/docs/auth/web/cordova
// <universal-links>
//         <host name="remed-fey.firebaseapp.com" scheme="https">
//             <path url="/__/auth/callback" />
//         </host>
//     </universal-links>

//"cordova-plugin-ionic-webview": "^4.1.3",

//"cordova-plugin-ionic-webview": {},

//<plugin name="cordova-plugin-ionic-webview" spec="^4.1.3" />
